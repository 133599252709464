*, *::after, *::before {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
}