.more-info {
  color: #555;
}

.back-home {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  border: none;
  background: transparent;
  padding: 0px;
}
.back-home svg {
  margin-right: 14px;
}

.arrow-side {
  margin-right: 6px;
  transform: translateY(3px);
}

.medicineFootprint > div {
  width: 100%;
  min-height: 100vh;
  display: flex;
}
.medicineFootprint > div > div {
  flex-grow: 1;
  padding: 50px 56px;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: auto;
  max-width: 1100px;
}
.medicineFootprint > div > div > button {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  border: none;
  background: transparent;
  padding: 0px;
}
.medicineFootprint > div > div > button svg {
  margin-right: 14px;
}
.medicineFootprint > div > div h2 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  margin-top: 26px;
  margin-bottom: 16px;
}
.medicineFootprint > div > div p {
  font-size: 16px;
  line-height: 18px;
  margin-top: 0px;
  margin-bottom: 40px;
  font-weight: 500;
}
.medicineFootprint .more-info {
  margin-top: 30px;
  font-size: 12px;
}

.selected-product {
  display: flex;
}
.selected-product > div {
  flex-grow: 1;
  padding: 50px 56px;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: auto;
  max-width: 1100px;
}
.selected-product .first {
  fill: #283;
}
.selected-product .second {
  fill: #4c6;
}
.selected-product .third {
  fill: #aeb;
}
.selected-product h4 {
  font-weight: 600;
  margin-bottom: 0;
}
.selected-product .info {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-template-rows: 1fr 1fr;
  width: auto;
  row-gap: 10px;
  font-weight: 550;
}
.selected-product .info p {
  line-height: 24px;
}
.selected-product .info a {
  text-decoration: underline;
  color: #395;
  font-weight: 700;
}
.selected-product .no-i {
  margin-top: 0px;
  display: block;
}
.selected-product tspan {
  fill: #353 !important;
}
.selected-product .recharts-legend-item-text {
  color: #353 !important;
}
.selected-product .recharts-sector {
  transition: 0.3s;
}
.selected-product .recharts-default-legend {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/*# sourceMappingURL=style.css.map */
